<template>
    <div></div>
</template>

<script>
export default {
    name: 'Home',
    data() {
        return {
            displayLoader: false,
        };
    },
    async mounted() {
        /* if (!this.currentToken) {
            await this.$router.push('/login');
        } else {
            this.displayLoader = true;
            try {
                const result = await this.$store.dispatch('me/get');

                switch (result.perfil.descripcion) {
                    case 'Empleado':
                        await this.$router.push('/u');
                        break;
                    case 'Recursos Humanos':
                        await this.$router.push('/a');
                        break;
                    default:
                        await this.$router.push('/account');
                        break;
                }
            } catch (e) {
                await this.$store.dispatch('auth/logout');
                await this.$router.push('/login');
            } finally {
                this.displayLoader = false;
            }
        } */
    },
    computed: {
        currentToken() {
            return this.$store.state.auth.token;
        },
        currentPerfil() {
            return this.$store.state.me.perfil;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
};
</script>
